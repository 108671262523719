<template>
  <page-loading-animation v-if="isLoading" :is-loading="isLoading" />

  <!--Page Content-->
  <div v-else>
    <!--Header-->
    <page-title
      :divider="true"
      icon="noticeBoard"
      info="Create and administer non-emergency notices."
      title="Notice Board"
    />

    <!--Action Bar-->
    <div class="d-flex align-center mt-4">
      <!--Search-->
      <app-form-field
        form-type="textInput"
        append-icon="icons8-search"
        class="mr-4"
        :clearable="true"
        label="Notice Title"
        style="width: 100%"
        v-model.trim="searchByNoticeTitle"
      />

      <!--Add Button-->
      <app-btn
        @click.native="openRightPanel"
        class="mr-4"
        icon="add"
        label="Add"
      />

      <!--Filter Button - with a numbered badge-->
      <div style="position: relative">
        <app-btn
          @click.native="toggleFiltersVisibility"
          class="mr-4"
          color="appWhite"
          icon="filter"
          icon-color="primary"
        />
        <span v-if="computedNumberOfActiveFilters > 0" class="badge">{{
          computedNumberOfActiveFilters
        }}</span>
      </div>

      <!--More Actions Menu-->
      <more-actions-menu
        @emitMenuSelection="handleEmittedMenuSelection"
        :menuList="moreActionsMenuOption"
      />
    </div>

    <!--Clear Filters-->
    <div
      v-if="computedNumberOfActiveFilters > 0"
      class="d-flex justify-end mt-4"
    >
      <app-btn
        @click.native="clearFilters"
        icon="close"
        label="Clear Filters"
      />
    </div>

    <!--Table-->
    <v-data-table
      v-if="$vuetify.breakpoint.width >= 600"
      class="appWhite rounded-lg mt-4"
      :headers="computedHeaders"
      :items="computedTableData"
    >
      <!--ID-->
      <template v-slot:item.entityId="{ item }">
        <app-text size="small">{{ item.entityId }}</app-text>
      </template>

      <!--Visibility-->
      <template v-slot:item.noticeVisibility="{ item }">
        <app-icon
          v-if="item.noticeVisibility === 'Visible'"
          color="green"
          icon="success"
          size="32"
          :title="item.noticeVisibility"
        />
        <app-icon
          v-if="item.noticeVisibility === 'Hidden'"
          color="red"
          icon="cancel"
          size="32"
          :title="item.noticeVisibility"
        />
      </template>

      <!--Priority-->
      <template v-slot:item.noticePriority="{ item }">
        <div
          :class="getNoticeColor(item.noticePriority)"
          class="d-flex justify-center align-self-center rounded-pill"
          style="height: 24px; width: 24px; margin: auto"
          :title="`${item.noticePriority} Priority`"
        />
      </template>

      <!--Title-->
      <template v-slot:item.noticeTitle="{ item }">
        <app-text size="small">{{ item.noticeTitle }}</app-text>
      </template>

      <!--Send To Type-->
      <template v-slot:item.noticeSendToType="{ item }">
        <app-text size="small">{{ item.noticeSendToType }}</app-text>
      </template>

      <!--Created-->
      <template v-slot:item.createdDateTime="{ item }">
        <app-text size="small">{{
          MIX_formatDate(Number(item.createdDateTime), "long")
        }}</app-text>
      </template>

      <!--Action Button-->
      <template v-slot:item.action="{ item }">
        <app-icon
          @click.native="openRightPanel(item)"
          class="cursorPointer"
          color="primary"
          icon="arrowForward"
        />
      </template>
    </v-data-table>

    <!--Mobile Cards-->
    <div v-if="$vuetify.breakpoint.width < 600">
      <!--No data message-->
      <app-text
        v-if="!computedTableData.length"
        class="text-center mt-4"
        color="grey9"
        >You have no Notices to view
      </app-text>

      <notice-board-mobile-card
        v-for="item in computedTableData"
        :key="item.entityId"
        @click.native="openRightPanel(item)"
        :cardData="item"
      />
    </div>

    <!--Filters Panel ------------------------------------------------------------------------------------------ -->
    <filter-panel
      :is-filters-panel-visible="isFiltersPanelVisible"
      @toggle="toggleFiltersVisibility"
    >
      <div class="pa-4">
        <!--Notice Priority-->
        <page-break-title title="Priorities" />
        <div class="mt-4">
          <app-form-field
            form-type="autoComplete"
            :deletable-chips="true"
            :items="noticeBoardPriorityOptions"
            label="Notice Priority"
            :multiple="true"
            :small-chips="true"
            v-model.trim="filterByNoticePriority"
          />
        </div>

        <!--Visibility-->
        <page-break-title class="mt-8" title="Visibility" />
        <div class="mt-4">
          <app-form-field
            form-type="autoComplete"
            :deletable-chips="true"
            :items="noticeBoardVisibilityOptions"
            label="Notice Visibility"
            :multiple="true"
            :small-chips="true"
            v-model.trim="filterByNoticeVisibility"
          />
        </div>

        <!--Send To Type-->
        <page-break-title class="mt-8" title="Send To" />
        <div class="mt-4">
          <app-form-field
            form-type="autoComplete"
            :deletable-chips="true"
            :items="['Users', 'Levels', 'Types', 'All']"
            label="Send To"
            :multiple="true"
            :small-chips="true"
            v-model.trim="filterByNoticeSendToTypes"
          />
        </div>
      </div>
    </filter-panel>

    <!--Right Side --------------------------------------------------------------------------------------------- -->
    <transition
      enter-active-class="animate__animated animate__fadeInRight animate__faster"
      leave-active-class="animate__animated animate__fadeOutRight animate__faster"
      mode="out-in"
    >
      <div
        v-if="isRightPanelVisible"
        class="appGrey formShadow"
        style="position: absolute; top: 0; bottom: 0; right: 0; overflow: auto"
        :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'"
      >
        <!--Header-->
        <div class="d-flex align-center primary pa-4">
          <app-text color="appWhite" size="normal">{{
            selectedItem?.noticeTitle || "New"
          }}</app-text>
          <v-spacer />
          <app-icon
            @click.native="closeRightPanel"
            class="cursorPointer"
            color="white"
            icon="close"
            size="32"
          />
        </div>

        <!--Action Bar-->
        <div class="d-flex align-center pa-4">
          <v-spacer />

          <edit-icon @click.native="editItem" :isActive="!isReadOnly" />
          <delete-icon
            v-if="
              selectedItem?.entityId &&
              ['Staff-Admin'].includes(MIX_getCurrentUser().userLevel)
            "
            @emitDeleteItem="deleteItem(selectedItem)"
            itemName="noticeTitle"
            :selectedItem="selectedItem"
          />
        </div>

        <!--Tabs-->
        <v-tabs v-model="tabs">
          <!--Overview-->
          <v-tab href="#overview">
            <app-text size="small">Overview</app-text>
          </v-tab>
        </v-tabs>

        <!--Tabs Content-->
        <v-tabs-items v-model="tabs">
          <!--Overview-->
          <v-tab-item value="overview">
            <notice-board-form
              @emitReloadPage="emittedReloadPage"
              class="pa-4"
              :formData="selectedItem"
              :isReadOnly="isReadOnly"
              :usersData="usersData"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </transition>
  </div>
</template>

<script>
import NoticeBoardForm from "@/views/noticeBoard/noticeBoardForm/NoticeBoardForm.vue"
import NoticeBoardMobileCard from "@/views/noticeBoard/noticeBoardMobileCard/NoticeBoardMobileCard.vue"

export default {
  name: "NoticeBoard",

  components: { NoticeBoardMobileCard, NoticeBoardForm },

  data: () => ({
    filterByNoticePriority: [],
    filterByNoticeSendToTypes: [],
    filterByNoticeVisibility: [],
    isFiltersPanelVisible: false,
    isLoading: false,
    isReadOnly: false,
    isRightPanelVisible: false,
    moreActionsMenuOption: [{ name: "Export", icon: "export" }],
    searchByNoticeTitle: "",
    searchByNoticePriority: "",
    selectedItem: {},
    tableHeaders: [
      { text: "ID", value: "id", align: "left", sortable: false, hidden: true },
      {
        text: "Visibility",
        value: "noticeVisibility",
        align: "center",
        sortable: false,
        width: "48px",
      },
      {
        text: "Priority",
        value: "noticePriority",
        align: "center",
        sortable: false,
        width: "48px",
      },
      { text: "Title", value: "noticeTitle", align: "left", sortable: false },
      {
        text: "Send To",
        value: "noticeSendToType",
        align: "left",
        sortable: false,
      },
      {
        text: "Created",
        value: "createdDateTime",
        align: "left",
        sortable: false,
      },
      {
        text: "",
        value: "action",
        align: "right",
        sortable: false,
        width: "48px",
      },
    ],
    tabs: "",

    // Data
    noticeBoardData: [],
    usersData: [],
  }),

  computed: {
    /**
     * Computed Export CSV
     *
     * Return the data and headers for the CSV export
     *
     * @returns {{headers: {}, data: *[]}}
     */
    computedExportCSV() {
      const t = this
      let data = []
      let headers = {}

      // Add the readable headers for the CSV columns
      headers = {
        entityId: "ID",
        noticeTitle: "Title",
        noticeDescription: "Description",
        noticePriority: "Priority",
        noticeVisibility: "Visibility",
        // noticeSendToListLevels: 'Send To List Levels',
        // noticeSendToListTypes: 'Send To List Types',
        // noticeSendToListUsers: 'Send To List Users',
        noticeSendToType: "Send To Type",
      }

      // Add the data
      t.computedTableData.forEach((entry) => {
        const DATA_OBJECT = {
          entityId: entry?.entityId,
          noticeTitle: entry?.noticeTitle,
          noticeDescription: entry?.noticeDescription,
          noticePriority: entry?.noticePriority,
          noticeVisibility: entry?.noticeVisibility,
          // noticeSendToListLevels: entry?.noticeSendToListLevels,
          // noticeSendToListTypes: entry?.noticeSendToListTypes,
          // noticeSendToListUsers: entry?.noticeSendToListUsers,
          noticeSendToType: entry?.noticeSendToType,
        }

        data.push(DATA_OBJECT)
      })

      return { headers, data }
    },

    /**
     * Computed Headers
     *
     * Remove hidden headers and only show the actions column to those with access.
     *
     * @returns an array of header objects
     */
    computedHeaders() {
      const t = this
      const CURRENT_USER_DATA = t.MIX_getCurrentUser()
      let headers = t.tableHeaders

      // Remove hidden headers
      headers = headers.filter((h) => !h.hidden)

      // Only those with access can view the actions column
      if (
        !["Staff-Admin", "Staff-Manager"].includes(CURRENT_USER_DATA.userLevel)
      )
        headers = headers.filter((h) => h.value !== "action")

      return headers
    },

    /**
     * Computed Number of Active Filters
     *
     * Count the number of active filters to display in the filters button badge.
     *
     * @returns {*}
     */
    computedNumberOfActiveFilters() {
      const t = this
      return [
        t.filterByNoticePriority.length,
        t.filterByNoticeSendToTypes.length,
        t.filterByNoticeVisibility.length,
      ].reduce((acc, curr) => acc + curr, 0)
    },

    /**
     * Computed Table Data
     *
     * Sort and return the table data.
     *
     * @returns {array[{}]} an array of objects
     */
    computedTableData() {
      const t = this
      let tableData = t.noticeBoardData

      // Search by Notice Title
      if (t.searchByNoticeTitle) {
        const SEARCH_CRITERIA = t.searchByNoticeTitle.toUpperCase()
        tableData = tableData.filter((item) => {
          const NOTICE_TITLE = item.noticeTitle.toUpperCase()
          return NOTICE_TITLE.includes(SEARCH_CRITERIA)
        })
      }

      // Filter by Notice Priority
      if (t.filterByNoticePriority.length)
        tableData = tableData.filter((item) =>
          t.filterByNoticePriority.includes(item.noticePriority)
        )

      // Filter by Notice Visibility
      if (t.filterByNoticeVisibility.length)
        tableData = tableData.filter((item) =>
          t.filterByNoticeVisibility.includes(item.noticeVisibility)
        )

      // Filter by Notice Send To Type
      if (t.filterByNoticeSendToTypes.length)
        tableData = tableData.filter((item) =>
          t.filterByNoticeSendToTypes.includes(item.noticeSendToType)
        )

      // Sort by Notice Title
      tableData = tableData.sort((a, b) =>
        a.createdDateTime < b.createdDateTime ? 1 : -1
      )

      return tableData
    },
  },

  methods: {
    /**
     * Clear Filters
     *
     * Clear all the filters.
     */
    clearFilters() {
      const t = this

      t.filterByNoticePriority = []
      t.filterByNoticeSendToTypes = []
      t.filterByNoticeVisibility = []
    },

    /**
     * Close Right Panel
     *
     * Close the right panel.
     */
    closeRightPanel() {
      const t = this

      t.tabs = ""
      t.selectedItem = {}
      t.isRightPanelVisible = false
    },

    /**
     *
     * Delete Item
     *
     * Delete the selected document by its ID.
     *
     * @param itemData {object} the item to delete
     */
    async deleteItem(itemData) {
      const t = this

      const RESPONSE = await t.MIX_redis_delete(
        "noticeBoard",
        itemData.entityId,
        itemData
      )

      // Handle any errors
      if (RESPONSE.hasErrors) {
        console.error("Error deleting Notice", RESPONSE.error)
        t.$sharedState.errorMessage =
          "There was a problem deleting the Notice, please try again."
        return
      }

      t.$sharedState.successMessage = "Deleted Notice"

      // Reload the data
      await t.loadData()

      // Close the panel
      t.closeRightPanel()
    },

    /**
     * Edit Item
     *
     * Toggle the editability of the form.
     */
    editItem() {
      const t = this

      t.isReadOnly = !t.isReadOnly
    },

    /**
     * Emitted Reload Page
     *
     * Close the panel and Reload the data to refresh the page.
     */
    emittedReloadPage() {
      const t = this

      t.closeRightPanel()
      t.loadData()
    },

    /**
     * Export Data
     *
     * Export the data to CSV.
     */
    exportData() {
      const t = this

      t.MIX_exportDocuments(
        t.computedExportCSV.headers,
        "Notice Board",
        t.computedExportCSV.data
      )
    },

    /**
     * Get Notice Color
     *
     * Return the color of the notice based on its Priority.
     *
     * @param noticePriority {string} the priority of the notice
     * @returns {string} the color of the notice
     */
    getNoticeColor(noticePriority) {
      let noticeColor = "grey"

      switch (noticePriority) {
        case "High":
          noticeColor = "red"
          break
        case "Medium":
          noticeColor = "orange"
          break
        case "Low":
          noticeColor = "green"
          break
        default:
          noticeColor = "grey"
      }

      return noticeColor
    },

    /**
     * Handle Emitted Menu Selection
     *
     * Take the emitted selection from the menu, and call the relevant function.
     *
     * @param selection a string-based menu option
     */
    handleEmittedMenuSelection(selection) {
      const t = this

      if (selection === "Export") t.exportData()
    },

    /**
     * Load Data
     *
     * Load all the data required for the page.
     *
     * @returns {Promise<void>}
     */
    async loadData() {
      const t = this

      t.isLoading = true

      await Promise.all([t.loadNoticeBoardData(), t.loadUsersData()])

      t.isLoading = false
    },

    /**
     * Load Notice Board Data
     *
     * Load the required data from the database.
     *
     * @returns {Promise<void>}
     */
    async loadNoticeBoardData() {
      const t = this

      // Fetch the data
      // const RESPONSE = await t.MIX_redis_getAll('noticeBoard')
      const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
        "NoticeBoard",
        [],
        [
          { field: "createdDateTime" },
          { field: "noticeDescription" },
          { field: "noticePriority" },
          { field: "noticeSendToType" },
          { field: "noticeSendToListUsers" },
          { field: "noticeSendToListLevels" },
          { field: "noticeSendToListTypes" },
          { field: "noticeTitle" },
          { field: "noticeVisibility" },
          { field: "noticeFromDate" },
          { field: "noticeToDate" },
        ]
      )

      // Handle any errors
      if (RESPONSE.hasErrors) {
        console.error("Error getting Notice Board data: ", RESPONSE.error)
        t.$sharedState.errorMessage =
          "There was a problem getting the Notice Board data, please try again."
        return
      }

      // Convert any required strings back to numbers
      t.noticeBoardData = RESPONSE.data.map((el) => {
        return {
          ...el,
          createdDateTime: Number(el.createdDateTime),
        }
      })
    },

    /**
     * Load Users Data
     *
     * Load the required data from the database.
     *
     * @returns {Promise<void>}
     */
    async loadUsersData() {
      const t = this

      // Fetch the data
      // const RESPONSE = await t.MIX_redis_getAll('user')
      const RESPONSE = await t.MIX_redis_getEntitiesByWhereAndFields(
        "User",
        [],
        [{ field: "userName" }, { field: "userLevel" }, { field: "userType" }]
      )

      // Handle any errors
      if (RESPONSE.hasErrors) {
        console.error("Error getting Users data: ", RESPONSE.error)
        t.$sharedState.errorMessage =
          "There was a problem getting the Users data, please try again."
        return
      }

      // Assign the data
      t.usersData = RESPONSE.data.sort((a, b) =>
        a.userName > b.userName ? 1 : -1
      )
    },

    /**
     * Open Form
     *
     * Open the form dialog.
     * If no item is passed in, the form will open as new (blank).
     *
     * @param item the selected key
     */
    openRightPanel(item) {
      const t = this

      // If an item is passed in, set the form to read only and set the selected item
      if (item?.entityId) {
        t.isReadOnly = true
        t.selectedItem = { ...item }
      }

      // Otherwise, set the form to editable and clear the selected item
      else {
        t.isReadOnly = false
        t.selectedItem = {}
      }

      t.isRightPanelVisible = true
    },

    /**
     * Toggle Filters Panel Visibility
     *
     * Toggle the visibility of the filters panel.
     */
    toggleFiltersVisibility() {
      const t = this

      t.isFiltersPanelVisible = !t.isFiltersPanelVisible
    },
  },

  mounted() {
    const t = this

    t.loadData()
  },
}
</script>

<style scoped></style>
