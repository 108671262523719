<template>

	<page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

	<!--Page Content-->
	<div v-else>

		{{ computedInit }}

		<!--Photo | UserName | Position | isManager-->
		<div :class="$vuetify.breakpoint.width < 600 ? 'd-flex flex-column' : 'd-flex'">

			<!--Profile Picture-->
			<div class="appWhite d-flex flex-column rounded-lg mr-4 pa-2"
				 :style="$vuetify.breakpoint.width < 800 ? 'height: 204px; width: 100%' : 'aspect-ratio: 1/1; height: 204px; width: 204px'">

				<!--Temporary Profile Picture-->
				<v-img v-if="tempProfilePicture"
					   :src="tempProfilePicture"
					   class="rounded-lg" max-height="204"
					   :style="$vuetify.breakpoint.width < 800 ? 'height: 188px' : 'aspect-ratio: 1/1; height: 188px; width: 188px'"/>

				<!--Profile Picture-->
				<v-img v-if="!tempProfilePicture && form.userProfilePicture"
                        :src="getProfileImagePath"
					   class="rounded-lg" max-height="204"
					   :style="$vuetify.breakpoint.width < 800 ? 'height: 188px' : 'aspect-ratio: 1/1; height: 188px; width: 188px'"/>

				<!--Default Profile Picture-->
				<app-icon v-if="!form.userProfilePicture"
						  cover
						  class="d-flex align-self-center"
						  icon="user"
						  size="204"/>

				<!--Image Upload button-->
				<image-upload v-if="!isReadOnly"
							  @emitImageUpload="emittedImageUpload"
							  :id-for-image="form.entityId"
							  folder="profilePictures"
							  image-for="profilePicture"
							  style="position: absolute"/>

			</div>

			<!--UserName | isManager | Add Photo-->
			<div class="d-flex flex-column" :class="$vuetify.breakpoint.width < 600 && 'mt-4'">

				<!--User Name-->
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.userName"
								:error-message="errors.userNameErrorMessage"
								label="Name"
								v-model.trim="form.userName"/>

				<!--User Position-->
				<app-form-field form-type="textInput"
								class="mt-4"
								:disabled="isReadOnly"
								:error="errors.userPosition"
								:error-message="errors.userPositionErrorMessage"
								label="Position"
								v-model.trim="form.userPosition"/>

				<!--isManager Checkbox-->
				<div class="d-flex align-center mt-4">

					<!--Icon-->
					<!--Outer div is required to keep the check box's aspect ratio-->
					<div>
						<div @click="handleIsManagerSelection"
							 class="appWhite d-flex align-center justify-center rounded-lg mr-4"
							 style="aspect-ratio: 1/1; height: 56px; width: 56px">
							<app-icon :color="form.userLevel === 'Organisation-Manager' ? 'green' : 'red'"
									  :icon="form.userLevel === 'Organisation-Manager' ? 'check' : 'close'"
									  size="32"/>
						</div>
					</div>

					<!--Label-->
					<div>
						<app-text>Is this a Manager?</app-text>
						<app-text color="grey9" size="small">
							Checking this will send an email inviting them on to SWAPP,
							and allow them to log in and administer this Organisation.
						</app-text>
					</div>

				</div>

			</div>

		</div>

		<form-section-title class="mt-8"
							description="You are not able to change the email address at present"
							title="Contact Details"/>

		<!--Email | Phone-->
		<v-row no-gutters>

			<!--Email-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? '12' : '6'">
				<app-form-field form-type="textInput"
								:disabled="!!formData?.userData?.entityId || isReadOnly"
								:error="errors.userEmail"
								:error-message="errors.userEmailErrorMessage"
								label="Email"
								:title="formData?.userData?.entityId && 'Email addresses cannot be changed at present'"
								v-model.trim="form.userEmail"/>
			</v-col>

			<!--Phone-->
			<v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'"
				   :cols="$vuetify.breakpoint.width < 600 ? '12' : '6'">
				<app-form-field form-type="textInput"
								:disabled="isReadOnly"
								:error="errors.userTelephone"
								:error-message="errors.userTelephoneErrorMessage"
								label="Phone Number"
								v-model.trim="form.userTelephone"/>
			</v-col>

		</v-row>

		<form-section-title class="mt-8"
							description="Default zones are inherited from the Organisation, but can be changed on a per user basis. (contact Admin to change this)"
							title="User Default Zones"/>

		<!--User Default Zones-->
		<!--Zones-->
		<app-text v-if="!userZones.length" class="text-center" color="grey9">Zones will be shown here once the user is
			created
		</app-text>
		<div v-else style="display: grid; grid-template-columns: repeat(5, 1fr)">

			<!--Zone 1-->
			<div v-for="zone in userZones" :key="zone.zone"
				 class="appWhite d-flex flex-column align-center rounded-lg mr-4 pa-4"
				 :title="MIX_getCurrentUser().userLevel !== 'Staff-Admin' && 'Contact Admin for changes'">
				<app-text size="small">{{ zone.zone }}</app-text>
				<app-icon @click.native="handleZoneSelection(zone)"
						  :class="MIX_getCurrentUser().userLevel !== 'Staff-Admin' && 'cursorNotAllowed'"
						  :color="zone.hasAccess ? 'green' : 'red'"
						  :disabled="isReadOnly"
						  :icon="zone.hasAccess ? 'success' : 'cancel'"
						  size="48"/>
			</div>

		</div>

		<!--Save Button-->
		<div v-if="!isReadOnly" class="d-flex justify-end mt-4">
			<app-btn @click.native="handleSave"
					 color="green"
					 icon="save"
					 label="Save"/>
		</div>

	</div>

</template>

<script>

export default {

	name: "OrganisationUserForm",

	props: ['formData', 'isReadOnly'],

	data: () => ({
		errors: {
			userEmail: false,
			userEmailErrorMessage: '',
			userName: false,
			userNameErrorMessage: '',
			userPosition: false,
			userPositionErrorMessage: '',
			userTelephone: false,
			userTelephoneErrorMessage: '',
		},
		// User form copied from Users - be aware of changes
		form: {
			entityId: '',
			userAcceptedTermsAndConditions: true,
			userAccessAndAbilities: [],
			userDefaultZones: '',
			userEmail: '',
			userFbId: '',
			userInternalIdNumber: '',
			userLastSwappDateTime: 0,
			userLastSwappLocationId: '',
			userLastSwappMethod: '',
			userLastSwappSiteId: '',
			userLevel: 'Organisation-User',
			userName: '',
			userNotes: '',
			userObservationResponsibilities: [],
			userOrganisation: '',
			userPayGrade: '',
			userPayrollNumber: '',
			userPosition: '',
			userProfilePicture: '',
			userQualifications: [],
			userRole: 'User',
			userStatus: 'Approved',
			userSwappStatus: 'Out',
			userTeams: [],
			userTelephone: '',
			userType: 'Organisation',

			createdUserId: '',
			createdDateTime: 0,
			createdUserName: '',
			modifiedUserId: '',
			modifiedDateTime: 0,
			modifiedUserName: '',
			isDeleted: false,
			deletedUserId: '',
			deletedDateTime: 0,
			deletedUserName: '',
		},
		isLoading: false,
		originalUserLevel: '',
		tempProfilePicture: '',
		userZones: [],
	}),

	computed: {
        /**
		 * Get Profile Image Path
		 * 
		 * Computed property to handle image path generation with logging
		 */
         getProfileImagePath() {
            const t = this;
            // Get file data from the fileData object
            const fileName = t.$props.formData?.fileData?.fileName;
            const fileToken = t.$props.formData?.fileData?.fileToken;
            
            const imagePath = t.MIX_getImagePath('profilePictures', fileName, fileToken);
            
            return imagePath;
        },
		/**
		 * Computed Init
		 *
		 * When the component is created, check if there is any data to populate the form with.
		 * If there is, populate the form.
		 */
		computedInit() {
			const t = this
			const FORM_DATA = t.$props.formData

			if (FORM_DATA?.userData?.entityId) {
				t.form = FORM_DATA.userData
				t.userZones = t.parsedZones(FORM_DATA.userData.userDefaultZones)
			}
		},

	},

	methods: {

		/**
		 * Clear Errors
		 *
		 * Clear all errors and their messages.
		 */
		clearErrors() {
			const t = this

			for (const error in t.errors) {

				if (typeof t.errors[error] === 'string') t.errors[error] = ''
				if (typeof t.errors[error] === 'boolean') t.errors[error] = false

			}

		},

		async createItem() {
			const t = this

			t.isLoading = true

			t.form.userOrganisation = t.$props.formData.organisationData.entityId

			const RESPONSE = await t.MIX_redis_createRegisteredUser(t.form)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error creating User: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem creating this User, please try again.'
				return
			}

			t.isLoading = false

			t.$emit('emitReloadPage')
			t.$sharedState.successMessage = 'Created User'
		},

		/**
		 * Emitted Image Upload
		 *
		 * When an image is uploaded, break apart the downloadURL and save what's required.
		 * Then, update the form with the image ID.
		 *
		 * @param payload - The image data and download URL
		 */
		async emittedImageUpload(payload) {
			const t = this

			// Set a temporary profile picture, until the form is saved
			t.tempProfilePicture = payload.downloadURL

			const {FOLDER_NAME, IMAGE_NAME, TOKEN} = t.MIX_extractImageDataFromUrl(payload.downloadURL)

			// Save the image data to the DB (Files collection), and add the returned image ID to the form
			t.form.userProfilePicture = await t.saveImageData(
				t.form.entityId,
				FOLDER_NAME,
				IMAGE_NAME,
				payload.image.size,
				TOKEN,
				payload.image.type
			)

		},

		/**
		 * Handle Is Manager Selection
		 *
		 * When the isManager checkbox is clicked, toggle the userLevel.
		 * To be a manager, the userLevel must be 'Organisation-Manager'.
		 * NB: Only Managers can administer the Organisation.
		 */
		handleIsManagerSelection() {
			const t = this

			// Don't allow changes if read only
			if (t.isReadOnly) return

			t.form.userLevel = t.form.userLevel === 'Organisation-Manager' ? 'Organisation-User' : 'Organisation-Manager'
			t.form.userRole = t.form.userLevel === 'Organisation-Manager' ? 'Manager' : 'User'
		},

		handleSave() {
			const t = this

			// Validate the form
			if (!t.validateForm()) return

			// Stringify the userDefaultZones
			t.form.userDefaultZones = JSON.stringify(t.userZones)

			// Create
			if (!t.form.entityId) t.createItem()

			// Update
			if (t.form.entityId) t.updateItem()
		},

		/**
		 * Handle Zone Selection
		 *
		 * When a zone is clicked, toggle the zone's hasAccess property.
		 */
		handleZoneSelection(zone) {
			const t = this

			// Don't allow changes if read only
			if (t.MIX_getCurrentUser().userLevel !== 'Staff-Admin' || t.isReadOnly) return

			const ZONE_INDEX = t.userZones.findIndex(userZone => userZone.zone === zone.zone)

			// Update the zone in the userZones array
			if (ZONE_INDEX !== -1) t.userZones[ZONE_INDEX].hasAccess = !t.userZones[ZONE_INDEX].hasAccess

			return zone
		},

		/**
		 * Parsed Zones
		 *
		 * Parses the userDefaultZones data.
		 * The zones data is stored as a string in the DB (either an empty string, or a stringified array of objects),
		 * so may need to be parsed before it can be used.
		 *
		 * If the data is a string, it will be parsed, otherwise it will be returned as is.
		 *
		 * @param zonesData {string|*[]} - The userDefaultZones data
		 * @returns {*[]} - The parsed userDefaultZones data
		 */
		parsedZones(zonesData) {
			let result = []

			// If the data is a string, parse it
			if (typeof zonesData === 'string') {
				try {
					result = JSON.parse(zonesData)
				} catch (error) {
					console.error('Error parsing userDefaultZones: ', error)
				}
			}
			// If the data is an array, return it as is
			else if (Array.isArray(zonesData)) result = zonesData

			return result
		},

		/**
		 * Save Image Data
		 *
		 * Save the image data to the DB (Files collection).
		 *
		 * @param fileDocLink {string} - The file's document link
		 * @param fileFolder {string} - The storage folder
		 * @param fileName {string} - The file name
		 * @param fileSize {number} - The file size
		 * @param fileToken {string} - The file token
		 * @param fileType {string} - The file type
		 * @returns {Promise<*>} - The image ID
		 */
		async saveImageData(fileDocLink, fileFolder, fileName, fileSize, fileToken, fileType) {
			const t = this

			const IMAGE_DATA = {
				fileDocLink,
				fileFolder,
				fileName,
				fileSize,
				fileToken,
				fileType,
				createdDateTime: 0,
				createdUserId: '',
				createdUserName: '',
				modifiedDateTime: 0,
				modifiedUserId: '',
				modifiedUserName: '',
				isDeleted: false,
				deletedDateTime: 0,
				deletedUserId: '',
				deletedUserName: '',
			}

			const RESPONSE = await t.MIX_redis_create('file', IMAGE_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error saving File Data to DB:', RESPONSE.errors)
				t.$sharedState.errorMessage = 'There was a problem uploading your file, please try again.'
				return
			}

			return RESPONSE.data?.entityId
		},

		/**
		 * Update Organisation User
		 *
		 * Update a single Organisation User.
		 * - Update the User data
		 * - Disable/Enable the User's Firebase account based on their userLevel
		 * - Add/Remove the User's entityId from the Organisation's organisationManagers array based on their userLevel
		 * - Update the Organisation data
		 */
		async updateItem() {
			const t = this
			const FORM_DATA = t.form

			const RESPONSE = await t.MIX_redis_updateOrganisationUser(FORM_DATA)

			// Handle any errors
			if (RESPONSE.hasErrors) {
				console.error('Error updating User: ', RESPONSE.error)
				t.$sharedState.errorMessage = 'There was a problem updating this User, please try again.'
				return
			}

			t.$sharedState.successMessage = 'Updated User'
			t.$emit('emitReloadPage')
		},

		/**
		 * Validate Form
		 *
		 * Validate the form, and return true if it's valid.
		 * If it's not valid, return false and set the errors.
		 */
		validateForm() {
			const t = this

			// Clear any errors
			t.clearErrors()

			// UserName
			if (!t.form.userName) {
				t.errors.userName = true
				t.errors.userNameErrorMessage = 'Please enter a Full Name'
			}

			// User Position
			if (!t.form.userPosition) {
				t.errors.userPosition = true
				t.errors.userPositionErrorMessage = 'Please enter a Position'
			}

			// User Email - Only required if the user is a manager
			const USER_EMAIL = t.form.userEmail
			if (!USER_EMAIL) {
				t.errors.userEmail = true
				t.errors.userEmailErrorMessage = 'Please enter an Email address'
			}
			// Must be a (simple) valid email address
			else if (!t.MIX_isEmailValid(USER_EMAIL)) {
				t.errors.userEmail = true
				t.errors.userEmailErrorMessage = 'Email is not valid'
			}

			// Telephone
			const USER_TELEPHONE_NUMBER = t.form.userTelephone
			if (USER_TELEPHONE_NUMBER) {
				const VALIDATED_NUMBER = t.MIX_isTelephoneNumberValid(USER_TELEPHONE_NUMBER)
				if (!VALIDATED_NUMBER) {
					t.errors.userTelephone = true
					t.errors.userTelephoneErrorMessage = 'Invalid Telephone Number'
				} else {
					t.form.userTelephone = VALIDATED_NUMBER
				}
			}

			return !Object.values(t.errors).includes(true)
		}

	},

	mounted() {
		const t = this
		const FORM_DATA = t.$props.formData

		if (FORM_DATA?.userData?.entityId && FORM_DATA?.userData?.userLevel === 'Organisation-User') {
			t.originalUserLevel = 'Organisation-User'
		}
	}

}
</script>

<style scoped>

</style>
