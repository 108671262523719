<template>
  <page-loading-animation v-if="isLoading" :is-loading="isLoading" />

  <div v-else>
    <!--No Notices Message-->
    <div v-if="!noticeBoardData.length">
      <app-text>You have no Notices to view</app-text>
    </div>

    <!--Notice Cards-->
    <div
      v-for="item in computedNoticeBoardData"
      :key="item.entityId"
      class="rounded-lg mb-4"
      :style="`border-left: 16px solid ${getNoticeColor(item.noticePriority)}`"
    >
      <div class="appWhite pa-4">
        <!--Title | Date-->
        <div style="width: 100%">
          <!--Title | Date-->
          <div class="d-flex align-center justify-space-between">
            <!--Title-->
            <app-text size="normal-bold">{{ item.noticeTitle }}</app-text>

            <!--Date Posted-->
            <app-text color="grey9" size="small">
              {{ MIX_formatDate(Number(item.createdDateTime), "numeric") }}
            </app-text>
          </div>

          <!--Description-->
          <app-text class="mt-2">{{ item.noticeDescription }}</app-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoticeBoardWidget",

  data: () => ({
    isLoading: false,

    // Data
    noticeBoardData: [],
  }),

  computed: {
    /**
     * Computed Notice Board Data
     *
     * Check what type of noticeSendToType the notice is, and display/hide it accordingly.
     * Sort the notices by date (newest first), but all 'high' priority notices should be at the top.
     */
    computedNoticeBoardData() {
      const t = this
      const CURRENT_USER_DATA = t.MIX_getCurrentUser()
      const NOTICE_DATA = t.noticeBoardData
      const CURRENT_TIME = Date.now()
      let noticeData = []

      // Loop through all the notices
      NOTICE_DATA.forEach((notice) => {
        // Skip if hidden
        if (notice.noticeVisibility === "Hidden") return

        // Skip if dates are NaN
        const fromDate = parseInt(notice.noticeFromDate)
        const toDate = parseInt(notice.noticeToDate)

        console.log(`Notice "${notice.noticeTitle}" date check:`, {
          fromDate,
          toDate,
          currentTime: CURRENT_TIME,
          isWithinRange: CURRENT_TIME >= fromDate && CURRENT_TIME <= toDate,
        })

        // Skip if dates are NaN or not within range
        if (isNaN(fromDate) || isNaN(toDate)) return
        if (CURRENT_TIME < fromDate || CURRENT_TIME > toDate) return

        // Users
        if (
          notice.noticeSendToType === "Users" &&
          notice.noticeSendToListUsers.includes(CURRENT_USER_DATA.entityId)
        ) {
          noticeData.push(notice)
        }

        // User Levels
        if (
          notice.noticeSendToType === "Levels" &&
          notice.noticeSendToListLevels.includes(CURRENT_USER_DATA.userLevel)
        ) {
          noticeData.push(notice)
        }

        // User Types
        if (
          notice.noticeSendToType === "Types" &&
          notice.noticeSendToListTypes.includes(CURRENT_USER_DATA.userType)
        ) {
          noticeData.push(notice)
        }

        // All
        if (notice.noticeSendToType === "All") {
          noticeData.push(notice)
        }
      })

      // Sort the notices by date (newest first), but all 'high' priority notices should be at the top
      noticeData.sort((a, b) => {
        return a.noticePriority === "High"
          ? -1
          : b.noticePriority === "High"
          ? 1
          : b.createdDateTime - a.createdDateTime
      })

      return noticeData
    },
  },

  methods: {
    /**
     * Get Notice Color
     *
     * Return the color of the notice based on its priority.
     *
     * @param noticePriority {string} the priority of the notice
     * @returns {string} the color of the notice
     */
    getNoticeColor(noticePriority) {
      let noticeColor = "grey"

      switch (noticePriority) {
        case "High":
          noticeColor = "red"
          break
        case "Medium":
          noticeColor = "orange"
          break
        case "Low":
          noticeColor = "green"
          break
        default:
          noticeColor = "grey"
      }

      return noticeColor
    },

    /**
     * Load Data
     *
     * Load all the data required for the page.
     *
     * @returns {Promise<void>}
     */
    async loadData() {
      const t = this

      t.isLoading = true

      await Promise.all([
        await t.loadNoticeBoardData(),
        // await t.loadCurrentUserData()
      ])

      t.isLoading = false
    },

    /**
     * Load Notice Board Data
     *
     * Load the required data from the database.
     *
     * @returns {Promise<void>}
     */
    async loadNoticeBoardData() {
      const t = this

      const RESPONSE = await t.MIX_redis_getAll("noticeBoard")

      // Handle any errors
      if (RESPONSE.hasErrors) {
        console.error("Error getting Notice Board data: ", RESPONSE.error)
        t.$sharedState.errorMessage =
          "There was a problem loading the Notice Board, please try again."
        return
      }

      // Assign the data and ensure dates are numbers
      t.noticeBoardData = RESPONSE.data.map((notice) => ({
        ...notice,
        noticeFromDate: notice.noticeFromDate
          ? parseInt(notice.noticeFromDate)
          : null,
        noticeToDate: notice.noticeToDate
          ? parseInt(notice.noticeToDate)
          : null,
      }))
    },
  },

  created() {
    const t = this

    t.loadData()
  },
}
</script>

<style scoped></style>
