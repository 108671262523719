<template>
    <page-loading-animation v-if="isLoading" :is-loading="isLoading"/>

    <div v-else>
        <!--Information-->
        <app-text>
            Here, you can bulk create Users for your Organisation by uploading a ZIP file containing user profile images.
            <br><br>
            User accounts will be automatically created based on the image filenames.
            <br><br>
            Please note, users will not need to log into their account unless they have been assigned as a Manager after
            their account has been created.
        </app-text>

        <v-divider class="greyD my-4"/>

        <!--ZIP Instructions-->
        <app-text>
            Create a ZIP file containing profile images:
            <ul>
                <li>
                    <code>Image filenames</code> - Use either format:
                    <ul>
                        <li>camelCase: firstnameLastname.jpg (e.g., johnSmith.jpg)</li>
                        <li>PascalCase: FirstnameLastname.jpg (e.g., JohnSmith.jpg)</li>
                    </ul>
                </li>
                <li>
                    <code>Supported formats</code> - .jpg, .jpeg, .png
                </li>
                <li>
                    <code>Examples</code>:
                    <ul>
                        <li>johnSmith.jpg → creates user "John Smith"</li>
                        <li>JohnSmith.jpg → creates user "John Smith"</li>
                    </ul>
                </li>
            </ul>
        </app-text>

        <!--Files-->
        <div class="d-flex mt-4">
            <!--Hidden-->
            <input @change="handleZIP"
                   accept=".zip"
                   ref="zipInput"
                   style="display: none"
                   type="file">

            <!--Visible-->
            <app-btn @click.native="onZipClick"
                     :color="zipFile ? 'green' : 'primary'"
                     :icon="zipFile ? '' : 'add'"
                     :label="zipFile ? 'ZIP File Added' : 'Add a ZIP File'"/>
        </div>

        <!--Upload-->
        <div class="d-flex justify-end mt-4">
            <app-btn @click.native="uploadFiles"
                     color="green"
                     :disabled="!zipFile"
                     label="Upload Files"/>
        </div>

        <!--Results Dialog-->
        <v-dialog max-width="512" v-model="isResultsDialogVisible">
            <div class="appGrey rounded-lg pa-4">
                <!--Successful-->
                <div v-if="uploadResults?.successfulRecords?.length">
                    <app-text size="normal-bold">
                        {{ uploadResults.successfulRecords.length }} accounts were successfully created:
                    </app-text>

                    <div v-for="record in uploadResults.successfulRecords" :key="record.userEmail"
                         class="d-flex align-center mt-4">
                        <app-icon class="mr-4" color="green" icon="check" size="24"/>
                        <div>
                            <app-text>{{ record.userName }}</app-text>
                            <app-text color="grey9" size="small">{{ record.userEmail }}</app-text>
                        </div>
                    </div>
                </div>

                <!--Failed-->
                <div v-if="uploadResults?.failedRecords?.length">
                    <app-text size="normal-bold">
                        {{ uploadResults.failedRecords.length }} accounts were not created:
                    </app-text>

                    <div v-for="record in uploadResults.failedRecords" :key="record.userEmail"
                         class="d-flex align-center mt-4">
                        <app-icon class="mr-4" color="red" icon="close" size="24"/>
                        <div>
                            <app-text>{{ record.userName }}</app-text>
                            <app-text color="grey9" size="small">{{ record.userEmail }}</app-text>
                            <app-text color="red" size="small">{{ record.error }}</app-text>
                        </div>
                    </div>
                </div>

                <v-divider class="mt-4"/>

                <!--Close Button-->
                <div class="mt-4">
                    <app-btn @click.native="closeResultsDialog"
                             :block="true"
                             label="Close"/>
                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "OrganisationBulkUserForm",

    props: ['formData'],

    data: () => ({
        isLoading: false,
        isResultsDialogVisible: false,
        uploadResults: {},
        zipFile: null,
    }),

    methods: {
        closeResultsDialog() {
            const t = this
            t.isResultsDialogVisible = false
            t.$emit('emitReloadPage')
        },

        handleZIP(event) {
            this.zipFile = event.target.files[0]
        },

        onZipClick() {
            this.$refs.zipInput.click()
        },
        openResultsDialog() {
            this.isResultsDialogVisible = true
        },

        async uploadFiles() {
            const t = this
            if (!t.zipFile) return
            t.isLoading = true
            try {
                
                // Get current user data
                const currentUser = t.MIX_getCurrentUser()
                
                // Construct the user config data
                const USER_CONFIG_DATA = {
                    userOrganisation: t.$props.formData.organisationData.entityId,
                    organisationDomain: t.$props.formData.organisationData.organisationName.toLowerCase(),
                    userType: 'Organisation',
                    userRole: 'User',
                    userLevel: 'Organisation-User',
                    createdUserId: currentUser.entityId,       
                    createdUserName: currentUser.userName       
                }
                
                // Construct a FormData object
                const formData = new FormData()
                formData.append('zip', t.zipFile)
                formData.append('userConfigData', JSON.stringify(USER_CONFIG_DATA))
                
                const RESPONSE = await t.MIX_redis_bulkCreateUsers(formData)
                
                if (RESPONSE.hasErrors) {
                    console.error('Error bulk creating users: ', RESPONSE.errors)
                    t.$sharedState.errorMessage = 'There was a problem creating these users, please try again.'
                    return
                }
                
                t.uploadResults = RESPONSE.data
                t.openResultsDialog()
            } catch (error) {
                console.error('Error in upload:', error)
                t.$sharedState.errorMessage = 'An unexpected error occurred during upload.'
            } finally {
                t.isLoading = false
            }
        }
    },
}
</script>